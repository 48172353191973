import { SxProps } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { PREDICAMENT_STATE_NO, PREDICAMENT_STATE_YES } from "core/consts";
import RadioGroupV2 from "ds_legacy/components/RadioGroupV2";
import { RADIO_WHITE_BACKGROUND, WHITE } from "ds_legacy/materials/colors";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import { margin, padding, sizing } from "ds_legacy/materials/metrics";
import {
  Body,
  FONT_SIZE_14,
  FONT_SIZE_16,
  FONT_SIZE_18,
  FONT_WEIGHT_BOLD,
  Subheading,
} from "ds_legacy/materials/typography";
import {
  DatePickerWithLabel,
  FormSectionsWrapper,
  PatientFormsSectionHeader,
  PatientFormSubheading,
  Section,
  SectionRow,
  SMALL_INPUT_MIN_WIDTH,
  SMALL_INPUT_WIDTH,
  TextAreaWithLabel,
  TextInputWithLabel,
  YesNoRadioWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";
import { useGuardianOptions, useLivingSituationOptions } from "./hooks";

export function GKVGeneralFormPresenter() {
  const translations = useTranslations();
  const livingSituationOptions = useLivingSituationOptions();
  const guardianOptions = useGuardianOptions();

  return (
    <VerticalLayout width="100%">
      <FormSectionsWrapper>
        <Section>
          <SectionRow>
            <TextInputWithLabel
              bold
              label={translations.patientForms.generalForm.insuredPerson.name}
              large
              elementName="patient_name"
            />
            <TextInputWithLabel
              bold
              label={translations.patientForms.generalForm.insuredPerson.street}
              large
              elementName="patient_street_housenumber"
            />
          </SectionRow>
          <SectionRow>
            <TextInputWithLabel
              bold
              label={translations.patientForms.generalForm.insuredPerson.city}
              large
              elementName="patient_city_zipcode"
            />
            <TextInputWithLabel
              bold
              label={translations.patientForms.generalForm.insuredPerson.phone}
              large
              elementName="patient_phone_number"
            />
          </SectionRow>
          <SectionRow>
            <TextInputWithLabel
              bold
              label={
                translations.patientForms.generalForm.insuredPerson
                  .healthInsurance
              }
              large
              elementName="insurance_company_name"
            />
            <TextInputWithLabel
              bold
              label={
                translations.patientForms.generalForm.insuredPerson
                  .insuranceNumber
              }
              elementName="insurance_number"
            />
            <DatePickerWithLabel
              bold
              label={
                translations.patientForms.generalForm.insuredPerson.dateOfBirth
              }
              elementName="patient_birthdate"
            />
          </SectionRow>
        </Section>
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <PatientFormsSectionHeader>
          {translations.patientForms.generalForm.sectionI.title}
        </PatientFormsSectionHeader>
        <Section>
          <Subheading
            as="h5"
            margin={margin(0, 0, 0.5, 0.5)}
            style={{
              fontSize: FONT_SIZE_18,
              fontWeight: FONT_WEIGHT_BOLD,
            }}
          >
            {translations.patientForms.generalForm.sectionI.titleNeedForCare}
          </Subheading>
          <YesNoRadioWithLabel
            elementName="insured_details_application_insurance_benefits"
            label={
              translations.patientForms.generalForm.sectionI.questionNeedForCare
            }
          />
          <PatientFormSubheading>
            {
              translations.patientForms.generalForm.sectionI
                .titlePensionApplication
            }
          </PatientFormSubheading>
          <YesNoRadioWithLabel
            elementName="retirement_pension_applied"
            label={
              translations.patientForms.generalForm.sectionI
                .subtitleRetirementPension
            }
          />
          <TextAreaWithLabel
            elementName="retirement_pension_company"
            label={
              translations.patientForms.generalForm.sectionI
                .placeholderLabelInsuranceName
            }
            marginOverride={margin(0)}
          />
          <YesNoRadioWithLabel
            formLabelSx={{ padding: padding(2, 0, 1) }}
            elementName="retirement_pension_earning_capacity_applied"
            label={
              translations.patientForms.generalForm.sectionI
                .subtitlePensionReducedEarning
            }
          />
          <TextAreaWithLabel
            elementName="retirement_earning_capacity_company"
            label={
              translations.patientForms.generalForm.sectionI
                .placeholderLabelInsuranceName
            }
            marginOverride={margin(0)}
          />
          <PatientFormSubheading withBottomPadding>
            {
              translations.patientForms.generalForm.sectionI
                .questionLivingSituation
            }
          </PatientFormSubheading>
          <TextInputWithLabel
            elementName="living_lift_description"
            label={
              translations.patientForms.generalForm.sectionI
                .livingSituationFloor
            }
            marginOverride={margin(0)}
            labelWrapperSx={{ width: "100%" }}
            style={{
              width: SMALL_INPUT_WIDTH,
              minWidth: SMALL_INPUT_MIN_WIDTH,
              background: WHITE,
            }}
          />
          <YesNoRadioWithLabel
            elementName="living_lift"
            label={
              translations.patientForms.generalForm.sectionI
                .livingSituationElevator
            }
          />
          <TextAreaWithLabel
            elementName="living_special"
            label={
              translations.patientForms.generalForm.sectionI
                .livingSituationSpecifications
            }
            marginOverride={margin(0)}
          />
          <PatientFormSubheading>
            {translations.patientForms.generalForm.sectionI.questionHomeCare}
          </PatientFormSubheading>
          <RadioGroupV2
            elementName="living_situation"
            formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
            formLabelSx={{
              padding: padding(1, 0),
              fontSize: FONT_SIZE_14,
            }}
            label={
              translations.patientForms.generalForm.sectionI.subtitleHomeCare
            }
            options={livingSituationOptions}
            sideMutation={(_, mutate) =>
              mutate(null, "living_situation_at_home")
            }
            radioSx={RADIO_WHITE_BACKGROUND}
          />
          <YesNoRadioWithLabel
            elementName="domestic_situation_is_safe"
            label={
              translations.patientForms.generalForm.sectionI
                .currentHomeCareEnsured
            }
          />
          <PatientFormSubheading>
            {translations.patientForms.generalForm.sectionI.questionGp}
          </PatientFormSubheading>
          <SectionRow customPadding={padding(1, 0, 0)}>
            <TextInputWithLabel
              bold
              label={translations.patientForms.generalForm.sectionI.gpName}
              large
              elementName="general_practitioner_name"
            />
          </SectionRow>
          <Body
            as="p"
            fontSize={FONT_SIZE_16}
            fontWeight={FONT_WEIGHT_BOLD}
            margin={margin(3, 0, 0)}
          >
            {translations.patientForms.generalForm.sectionI.subtitleAddressGp}
          </Body>
          <SectionRow customPadding={padding(2, 0, 0)}>
            <TextInputWithLabel
              bold
              label={translations.patientForms.generalForm.sectionI.gpStreet}
              large
              elementName="general_practitioner_street"
            />
            <TextInputWithLabel
              bold
              label={translations.patientForms.generalForm.sectionI.gpZipCode}
              elementName="general_practitioner_zipcode"
            />
          </SectionRow>
          <SectionRow>
            <TextInputWithLabel
              bold
              label={translations.patientForms.generalForm.sectionI.gpCity}
              large
              elementName="general_practitioner_city"
            />
            <TextInputWithLabel
              bold
              label={
                translations.patientForms.generalForm.sectionI.gpPhoneNumber
              }
              large
              elementName="general_practitioner_phone_number"
            />
          </SectionRow>
          <PatientFormSubheading>
            {translations.patientForms.generalForm.sectionI.questionGuardian}
          </PatientFormSubheading>
          <RadioGroupV2
            elementName="insured_details_guardian_type"
            label={
              translations.patientForms.generalForm.sectionI
                .accessibilityGuardianType
            }
            options={guardianOptions}
            formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
            formLabelSx={visuallyHidden as SxProps}
            radioGroupSx={{ flexDirection: "row", paddingTop: sizing(1) }}
            radioSx={RADIO_WHITE_BACKGROUND}
          />
          <SectionRow customPadding={padding(1, 0, 0)}>
            <TextInputWithLabel
              bold
              label={translations.patientForms.generalForm.sectionI.gpName}
              large
              elementName="insured_details_guardian_last_first_name"
            />
          </SectionRow>
          <Body
            as="p"
            fontSize={FONT_SIZE_16}
            fontWeight={FONT_WEIGHT_BOLD}
            margin={margin(3, 0, 0)}
          >
            {translations.patientForms.generalForm.sectionI.subtitleAddressGp}
          </Body>
          <SectionRow customPadding={padding(2, 0, 0)}>
            <TextInputWithLabel
              bold
              label={translations.patientForms.generalForm.sectionI.gpStreet}
              large
              elementName="insured_details_guardian_street_house_number"
            />
            <TextInputWithLabel
              bold
              label={translations.patientForms.generalForm.sectionI.gpZipCode}
              elementName="insured_details_guardian_zipcode"
            />
          </SectionRow>
          <SectionRow>
            <TextInputWithLabel
              bold
              label={translations.patientForms.generalForm.sectionI.gpCity}
              large
              elementName="insured_details_guardian_city"
            />
            <TextInputWithLabel
              bold
              label={
                translations.patientForms.generalForm.sectionI.gpPhoneNumber
              }
              large
              elementName="insured_details_guardian_phone"
            />
          </SectionRow>
          <PatientFormSubheading withBottomPadding>
            {
              translations.patientForms.generalForm.sectionI
                .questionSpecialRequirements
            }
          </PatientFormSubheading>
          <TextAreaWithLabel
            elementName="reason_for_reha_clinic"
            label={
              translations.patientForms.generalForm.sectionI
                .labelSpecialRequirements
            }
            marginOverride={margin(0)}
          />
        </Section>
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <PatientFormsSectionHeader>
          {translations.patientForms.generalForm.sectionTwo.title}
        </PatientFormsSectionHeader>
        <Section>
          <SectionRow>
            <TextInputWithLabel
              bold
              label={
                translations.patientForms.generalForm.sectionTwo.hospitalName
              }
              large
              elementName="hospital_name"
            />
            <TextInputWithLabel
              bold
              label={
                translations.patientForms.generalForm.sectionTwo
                  .hospitalAddressStreet
              }
              large
              elementName="hospital_address"
            />
          </SectionRow>
          <SectionRow>
            <TextInputWithLabel
              bold
              label={
                translations.patientForms.generalForm.sectionTwo
                  .hospitalAddressCity
              }
              large
              elementName="hospital_zipcode_city"
            />
            <TextInputWithLabel
              bold
              label={translations.patientForms.generalForm.sectionTwo.patientId}
              large
              elementName="case_id"
            />
          </SectionRow>
          <PatientFormSubheading>
            {
              translations.patientForms.generalForm.sectionTwo
                .detailsSocialWorker
            }
          </PatientFormSubheading>
          <SectionRow customPadding={padding(1, 0, 0)}>
            <TextInputWithLabel
              bold
              label={
                translations.patientForms.generalForm.sectionTwo
                  .socialWorkerName
              }
              large
              elementName="social_worker_full_name"
            />
            <TextInputWithLabel
              bold
              label={
                translations.patientForms.generalForm.sectionTwo
                  .socialWorkerPhone
              }
              large
              elementName="social_worker_phone_number"
            />
          </SectionRow>
          <SectionRow>
            <TextInputWithLabel
              bold
              label={
                translations.patientForms.generalForm.sectionTwo.socialWorkerFax
              }
              large
              elementName="social_worker_fax_number"
            />
          </SectionRow>
          <PatientFormSubheading withBottomPadding>
            {
              translations.patientForms.generalForm.sectionTwo
                .communicationWithPatient
            }
          </PatientFormSubheading>
          <YesNoRadioWithLabel
            elementName="communication_in_german_possible"
            label={
              translations.patientForms.generalForm.sectionTwo
                .communicationWithPatient
            }
            formLabelSx={visuallyHidden}
            sideMutation={(_value, mutate) => {
              mutate(null, "communication_other_language");
            }}
            subForms={{
              [PREDICAMENT_STATE_NO]: (
                <TextAreaWithLabel
                  elementName="communication_other_language"
                  flatModel
                  label={
                    translations.patientForms.generalForm.sectionTwo
                      .communicationNoGerman
                  }
                  marginOverride={margin(0)}
                />
              ),
            }}
          />
          <PatientFormSubheading withBottomPadding>
            {translations.patientForms.generalForm.sectionTwo.costCoverage}
          </PatientFormSubheading>
          <YesNoRadioWithLabel
            elementName="prefered_clinic_contacted"
            label={
              translations.patientForms.generalForm.sectionTwo.costCoverage
            }
            formLabelSx={visuallyHidden}
            sideMutation={(_value, mutate) => {
              mutate(null, "prefered_clinic_contacted_details");
              mutate(null, "prefered_clinic_contacted_start_date");
            }}
            subForms={{
              [PREDICAMENT_STATE_YES]: (
                <VerticalLayout gap={sizing(1)} padding={padding(0, 0, 1)}>
                  <TextAreaWithLabel
                    elementName="prefered_clinic_contacted_details"
                    flatModel
                    label={
                      translations.patientForms.generalForm.sectionTwo
                        .rehabDetails
                    }
                    marginOverride={margin(0)}
                  />
                  <DatePickerWithLabel
                    bold
                    elementName="prefered_clinic_contacted_start_date"
                    flatModel
                    inputSx={{
                      width: SMALL_INPUT_WIDTH,
                    }}
                    label={
                      translations.patientForms.generalForm.sectionTwo
                        .rehabIntakeDate
                    }
                    wrapperSx={{ width: "100%" }}
                  />
                </VerticalLayout>
              ),
            }}
          />
        </Section>
      </FormSectionsWrapper>
    </VerticalLayout>
  );
}
