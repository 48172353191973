import { SxProps } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import {
  EARLY_REHAB_CRITERIA,
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_OTHER,
  MUSCOSKELETAL_RESILIENCE,
  PERFORMED_TREATMENT,
  PREDICAMENT_STATE_NO,
  PREDICAMENT_STATE_UNKNOWN,
  PREDICAMENT_STATE_YES,
  REHAB_ADMISSION,
  REHAB_DIRECTIVE_TYPE,
  REHAB_FOCUS,
  TRANSPORTATION_TAXI,
  TRANSPORTATION_TYPE,
  TYPE_OF_REHAB,
} from "core/consts";
import { CheckboxGroupProps } from "ds_legacy/components/CheckboxGroup";
import RadioGroupV2, { RadioOptionV2 } from "ds_legacy/components/RadioGroupV2";
import { SelectOption } from "ds_legacy/components/SelectInput";
import { RADIO_WHITE_BACKGROUND } from "ds_legacy/materials/colors";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import { margin, padding, sizing } from "ds_legacy/materials/metrics";
import {
  CheckboxWithTextfieldProps,
  DatePickerWithLabel,
  SMALL_INPUT_MIN_WIDTH,
  SMALL_INPUT_WIDTH,
  TextAreaWithLabel,
  TextInputWithLabel,
  YesNoRadioWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";
import { INFECTIONS_CHARACTER_LIMIT } from "../utils";

export function useGenderOptions() {
  const translations = useTranslations();

  const genderOptions: RadioOptionV2[] = [
    {
      id: GENDER_MALE,
      value: GENDER_MALE,
      label: translations.patientForms.medicalForm.sectionOne.genderMale,
    },
    {
      id: GENDER_FEMALE,
      value: GENDER_FEMALE,
      label: translations.patientForms.medicalForm.sectionOne.genderFemale,
    },
    {
      id: GENDER_OTHER,
      value: GENDER_OTHER,
      label: translations.patientForms.medicalForm.sectionOne.genderDiverse,
    },
  ];

  return genderOptions;
}

export function useCauseOptions() {
  const translations = useTranslations();

  const CAUSE_OPTIONS: Record<number, string> = {
    0: translations.patientForms.medicalForm.sectionTwo.optionOne,
    1: translations.patientForms.medicalForm.sectionTwo.optionTwo,
    2: translations.patientForms.medicalForm.sectionTwo.optionThree,
    3: translations.patientForms.medicalForm.sectionTwo.optionFour,
    4: translations.patientForms.medicalForm.sectionTwo.optionFive,
    5: translations.patientForms.medicalForm.sectionTwo.optionSix,
  };

  const causeOptions: SelectOption[] = Object.entries(CAUSE_OPTIONS).map(
    ([key, label]) => ({
      id: key,
      label: label,
      value: key,
    }),
  );
  return causeOptions;
}

export function useComplicationOptions() {
  const translations = useTranslations();

  const complicationOptions: CheckboxWithTextfieldProps[] = [
    {
      checkboxElementName: "complication_cause_maindiagnosis",
      descriptionElementName: "complication_cause_maindiagnosis_description",
      label:
        translations.patientForms.medicalForm.sectionTwo.relatedToDiagnosis,
    },
    {
      checkboxElementName: "complication_cause_cardiovascular",
      descriptionElementName: "complication_cause_cardiovascular_description",
      label:
        translations.patientForms.medicalForm.sectionTwo.optionCardiovascular,
    },
    {
      checkboxElementName: "complication_cause_other",
      descriptionElementName: "complication_cause_other_description",
      label:
        translations.patientForms.medicalForm.sectionTwo
          .optionOtherComplication,
    },
  ];

  return complicationOptions;
}

export function useRehabAdmissionOptions() {
  const translations = useTranslations();

  const rehabAdmissionOptions: RadioOptionV2[] = [
    {
      id: REHAB_ADMISSION.DIRECT,
      value: REHAB_ADMISSION.DIRECT,
      label: translations.patientForms.medicalForm.sectionOne.optionDirect,
    },
    {
      id: REHAB_ADMISSION.WITHIN_TWO_WEEKS,
      value: REHAB_ADMISSION.WITHIN_TWO_WEEKS,
      label: translations.patientForms.medicalForm.sectionOne.withinTwoWeeks,
      subForm: (
        <DatePickerWithLabel
          bold
          elementName="rehab_admission_within_two_weeks_start_date"
          flatModel
          inputSx={{
            width: SMALL_INPUT_WIDTH,
            minWidth: SMALL_INPUT_MIN_WIDTH,
          }}
          label={
            translations.patientForms.medicalForm.sectionOne
              .earliestDatePossible
          }
          wrapperSx={{ width: "100%", marginBottom: sizing(2) }}
        />
      ),
    },
    {
      id: REHAB_ADMISSION.OUTSIDE_TWO_WEEKS,
      value: REHAB_ADMISSION.OUTSIDE_TWO_WEEKS,
      label: translations.patientForms.medicalForm.sectionOne.outsideTwoWeeks,
      subForm: (
        <>
          <DatePickerWithLabel
            bold
            elementName="rehab_admission_outside_two_weeks_start_date"
            flatModel
            inputSx={{
              width: SMALL_INPUT_WIDTH,
              minWidth: SMALL_INPUT_MIN_WIDTH,
            }}
            label={
              translations.patientForms.medicalForm.sectionOne
                .earliestDatePossible
            }
            wrapperSx={{ width: "100%", padding: padding(0, 0, 1) }}
          />
          <TextAreaWithLabel
            bold
            elementName="rehab_admission_outside_two_weeks_explanation"
            flatModel
            label={
              translations.patientForms.medicalForm.sectionOne.medicalReasons
            }
            marginOverride={margin(0, 0, 1)}
          />
        </>
      ),
    },
  ];

  return rehabAdmissionOptions;
}

export function usePerformedTreatmentOptions() {
  const translations = useTranslations();
  const performedTreatmentOptions: RadioOptionV2[] = [
    {
      id: PERFORMED_TREATMENT.SURGERY,
      value: PERFORMED_TREATMENT.SURGERY,
      label: translations.patientForms.medicalForm.sectionTwo.surgeries,
      subForm: (
        <VerticalLayout
          gap={sizing(1)}
          overflow="visible"
          margin={margin(0, 0, 0, 4.5)}
        >
          <HorizontalLayout gap={sizing(2)}>
            <DatePickerWithLabel
              bold
              elementName="operation_date"
              flatModel
              label={
                translations.patientForms.medicalForm.sectionTwo.surgeryDate
              }
            />
            <TextInputWithLabel
              bold
              elementName="operations"
              flatModel
              label={
                translations.patientForms.medicalForm.sectionTwo.surgeryOps
              }
            />
          </HorizontalLayout>
          <TextAreaWithLabel
            bold
            elementName="operation_description"
            flatModel
            label={
              translations.patientForms.medicalForm.sectionTwo
                .surgeryDescription
            }
            marginOverride={margin(0)}
          />
          <YesNoRadioWithLabel
            elementName="wound_closed"
            flatModel
            label={translations.patientForms.medicalForm.sectionTwo.wound}
          />
        </VerticalLayout>
      ),
    },
    {
      id: PERFORMED_TREATMENT.OTHER,
      value: PERFORMED_TREATMENT.OTHER,
      label: translations.patientForms.medicalForm.sectionTwo.otherTreatments,
      subForm: (
        <TextAreaWithLabel
          bold
          elementName="current_therapy"
          flatModel
          label={
            translations.patientForms.medicalForm.sectionTwo
              .treatmentsDescription
          }
          marginOverride={margin(0)}
        />
      ),
    },
  ];

  return performedTreatmentOptions;
}

export function useInfectionOptions() {
  const translations = useTranslations();

  const infectionOptions: RadioOptionV2[] = [
    {
      id: PREDICAMENT_STATE_NO,
      value: PREDICAMENT_STATE_NO,
      label: translations.actions.no,
    },
    {
      id: PREDICAMENT_STATE_YES,
      value: PREDICAMENT_STATE_YES,
      label: translations.actions.yes,
      subForm: (
        <TextAreaWithLabel
          characterLimit={INFECTIONS_CHARACTER_LIMIT}
          flatModel
          label={translations.patientForms.medicalForm.sectionTwo.typeOfGerms}
          elementName="infections"
          marginOverride={margin(0, 0, 1)}
          wrapperSx={{ padding: padding(0, 0, 0, 4.5) }}
        />
      ),
    },
    {
      id: PREDICAMENT_STATE_UNKNOWN,
      value: PREDICAMENT_STATE_UNKNOWN,
      label: translations.patientForms.medicalForm.sectionTwo.germsUnknown,
    },
  ];

  return infectionOptions;
}

export function useMusculoskeletalResilienceOptions() {
  const translations = useTranslations();

  const musculoskeletalResilienceOptions: RadioOptionV2[] = [
    {
      id: MUSCOSKELETAL_RESILIENCE.FULLY,
      value: MUSCOSKELETAL_RESILIENCE.FULLY,
      label: translations.patientForms.medicalForm.sectionThree.fully,
    },
    {
      id: MUSCOSKELETAL_RESILIENCE.PARTIALLY,
      value: MUSCOSKELETAL_RESILIENCE.PARTIALLY,
      label: translations.patientForms.medicalForm.sectionThree.partially,
      subForm: (
        <TextInputWithLabel
          elementName="reha_capacity_partial"
          flatModel
          endAdornment={translations.abbreviations.kilogram.abbreviation}
          marginOverride={margin(0, 0, 1, 4.5)}
        />
      ),
    },
    {
      id: MUSCOSKELETAL_RESILIENCE.STABLE,
      value: MUSCOSKELETAL_RESILIENCE.STABLE,
      label: translations.patientForms.medicalForm.sectionThree.stable,
    },
  ];

  return musculoskeletalResilienceOptions;
}

export function useEarlyRehabCriteriaOptions() {
  const translations = useTranslations();
  const earlyRehabCriteriaOptions: CheckboxGroupProps["items"] = [
    {
      id: EARLY_REHAB_CRITERIA.INTENSIVE_CARE_MONITORING,
      label:
        translations.patientForms.medicalForm.sectionThree
          .intensiveCareMonitoring,
    },
    {
      id: EARLY_REHAB_CRITERIA.INTERMITTENT_VENTILATION,
      label:
        translations.patientForms.medicalForm.sectionThree
          .intermittentVentilation,
    },
    {
      id: EARLY_REHAB_CRITERIA.BEHAVIOURAL_DISORDER,
      label:
        translations.patientForms.medicalForm.sectionThree.behaviouralDisorder,
    },
    {
      id: EARLY_REHAB_CRITERIA.COMMUNICATION_DISORDER,
      label:
        translations.patientForms.medicalForm.sectionThree
          .communicationDisorder,
    },
    {
      id: EARLY_REHAB_CRITERIA.TRACHEOSTOMA,
      label: translations.patientForms.medicalForm.sectionThree.tracheostoma,
    },
    {
      id: EARLY_REHAB_CRITERIA.ORIENTATION_DISORDER,
      label:
        translations.patientForms.medicalForm.sectionThree.orientationDisorder,
    },
    {
      id: EARLY_REHAB_CRITERIA.SWALLOWING_DISORDER,
      label:
        translations.patientForms.medicalForm.sectionThree.swallowingDisorder,
    },
  ];

  return earlyRehabCriteriaOptions;
}

export function useRehabFocusOptions() {
  const translations = useTranslations();

  const rehabFocusOptions: RadioOptionV2[] = [
    {
      id: REHAB_FOCUS.HEART,
      value: REHAB_FOCUS.HEART,
      label: translations.patientForms.medicalForm.sectionFive.heart,
    },
    {
      id: REHAB_FOCUS.NEUROLOGICAL_DISEASE,
      value: REHAB_FOCUS.NEUROLOGICAL_DISEASE,
      label:
        translations.patientForms.medicalForm.sectionFive.neurologicalDisease,
    },
    {
      id: REHAB_FOCUS.BLOOD_VESSELS,
      value: REHAB_FOCUS.BLOOD_VESSELS,
      label: translations.patientForms.medicalForm.sectionFive.bloodVessels,
    },
    {
      id: REHAB_FOCUS.ONCOLOGICAL_DISEASE,
      value: REHAB_FOCUS.ONCOLOGICAL_DISEASE,
      label:
        translations.patientForms.medicalForm.sectionFive.oncologicalDiseases,
    },
    {
      id: REHAB_FOCUS.INFLAMMATORY_DISEASE,
      value: REHAB_FOCUS.INFLAMMATORY_DISEASE,
      label:
        translations.patientForms.medicalForm.sectionFive.inflammatoryDiseases,
    },
    {
      id: REHAB_FOCUS.GYNAECOLOGICAL_DISEASE,
      value: REHAB_FOCUS.GYNAECOLOGICAL_DISEASE,
      label:
        translations.patientForms.medicalForm.sectionFive
          .gynaecologicalDiseases,
    },
    {
      id: REHAB_FOCUS.MUSCOLOSKELETAL_DISEASE,
      value: REHAB_FOCUS.MUSCOLOSKELETAL_DISEASE,
      label:
        translations.patientForms.medicalForm.sectionFive.musculoskeletalSystem,
    },
    {
      id: REHAB_FOCUS.SKIN_DISEASE,
      value: REHAB_FOCUS.SKIN_DISEASE,
      label: translations.patientForms.medicalForm.sectionFive.skinDiseases,
    },
    {
      id: REHAB_FOCUS.GASTROENTEROLOGICAL_DISEASE,
      value: REHAB_FOCUS.GASTROENTEROLOGICAL_DISEASE,
      label:
        translations.patientForms.medicalForm.sectionFive
          .gastroenterologicalDiseases,
    },
    {
      id: REHAB_FOCUS.PSYCHOSOMATIC_DISORDER,
      value: REHAB_FOCUS.PSYCHOSOMATIC_DISORDER,
      label:
        translations.patientForms.medicalForm.sectionFive
          .psychosomaticDisorders,
    },
    {
      id: REHAB_FOCUS.ENDOCRINE_DISEASE,
      value: REHAB_FOCUS.ENDOCRINE_DISEASE,
      label:
        translations.patientForms.medicalForm.sectionFive.endocrineDiseases,
    },
    {
      id: REHAB_FOCUS.MENTAL_ILLNESS,
      value: REHAB_FOCUS.MENTAL_ILLNESS,
      label: translations.patientForms.medicalForm.sectionFive.mentalIllnesses,
    },
    {
      id: REHAB_FOCUS.RESPIRATORY_DISEASE,
      value: REHAB_FOCUS.RESPIRATORY_DISEASE,
      label:
        translations.patientForms.medicalForm.sectionFive.respiratoryDiseases,
    },
    {
      id: REHAB_FOCUS.ADDICTIVE_DISORDER,
      value: REHAB_FOCUS.ADDICTIVE_DISORDER,
      label:
        translations.patientForms.medicalForm.sectionFive.addictiveDisorders,
    },
    {
      id: REHAB_FOCUS.NEPHROLOGICAL_DISEASE,
      value: REHAB_FOCUS.NEPHROLOGICAL_DISEASE,
      label:
        translations.patientForms.medicalForm.sectionFive.nephrologicalDiseases,
    },
    {
      id: REHAB_FOCUS.BLOOD_DISEASE,
      value: REHAB_FOCUS.BLOOD_DISEASE,
      label: translations.patientForms.medicalForm.sectionFive.bloodDiseases,
    },
    {
      id: REHAB_FOCUS.UROLOGICAL_DISEASE,
      value: REHAB_FOCUS.UROLOGICAL_DISEASE,
      label:
        translations.patientForms.medicalForm.sectionFive.urologicalDiseases,
    },
    {
      id: REHAB_FOCUS.GERIATRICS,
      value: REHAB_FOCUS.GERIATRICS,
      label: translations.patientForms.medicalForm.sectionFive.geriatrics,
    },
    {
      id: REHAB_FOCUS.OTHER,
      value: REHAB_FOCUS.OTHER,
      label: translations.patientForms.medicalForm.sectionFive.others,
      subForm: (
        <TextAreaWithLabel
          elementName="rehab_focus_other_description"
          flatModel
          label={
            translations.patientForms.medicalForm.sectionFive
              .otherTextfieldLabel
          }
          marginOverride={margin(0)}
        />
      ),
    },
  ];

  return rehabFocusOptions;
}

export function useRehabTypeOptions() {
  const translations = useTranslations();

  const rehabTypeOptions: RadioOptionV2[] = [
    {
      id: TYPE_OF_REHAB.MOBILE,
      value: TYPE_OF_REHAB.MOBILE,
      label: translations.patientForms.medicalForm.sectionFive.mobile,
    },
    {
      id: TYPE_OF_REHAB.MOBILE_OUTPATIENT,
      value: TYPE_OF_REHAB.MOBILE_OUTPATIENT,
      label: translations.patientForms.medicalForm.sectionFive.mobileOutpatient,
      subForm: (
        <TextAreaWithLabel
          elementName="recommended_reha_type_ambulant_mobil"
          flatModel
          label={
            translations.patientForms.medicalForm.sectionTwo
              .complicationsDescriptionLabel
          }
          marginOverride={margin(0, 0, 1)}
        />
      ),
    },
    {
      id: TYPE_OF_REHAB.STATIONARY,
      value: TYPE_OF_REHAB.STATIONARY,
      label: translations.patientForms.medicalForm.sectionFive.static,
      subForm: (
        <TextAreaWithLabel
          elementName="recommended_reha_type_stationary"
          flatModel
          label={
            translations.patientForms.medicalForm.sectionTwo
              .complicationsDescriptionLabel
          }
          marginOverride={margin(0, 0, 1)}
        />
      ),
    },
  ];

  return rehabTypeOptions;
}

export function useRehabTypeDirectiveOptions() {
  const translations = useTranslations();

  const rehabTypeDirectiveOptions: RadioOptionV2[] = [
    {
      id: REHAB_DIRECTIVE_TYPE.MYOCARDIAL_INFARCTION,
      value: REHAB_DIRECTIVE_TYPE.MYOCARDIAL_INFARCTION,
      label:
        translations.patientForms.medicalForm.sectionFive.myocardialInfarction,
    },
    {
      id: REHAB_DIRECTIVE_TYPE.BYPASS,
      value: REHAB_DIRECTIVE_TYPE.BYPASS,
      label: translations.patientForms.medicalForm.sectionFive.bypass,
    },
    {
      id: REHAB_DIRECTIVE_TYPE.HEART_FAILURE,
      value: REHAB_DIRECTIVE_TYPE.HEART_FAILURE,
      label: translations.patientForms.medicalForm.sectionFive.heartFailure,
    },
    {
      id: REHAB_DIRECTIVE_TYPE.ENDOPROSTHETIC_TREATMENT,
      value: REHAB_DIRECTIVE_TYPE.ENDOPROSTHETIC_TREATMENT,
      label:
        translations.patientForms.medicalForm.sectionFive
          .endoprostheticTreatment,
    },
    {
      id: REHAB_DIRECTIVE_TYPE.BACK_PAIN,
      value: REHAB_DIRECTIVE_TYPE.BACK_PAIN,
      label: translations.patientForms.medicalForm.sectionFive.backPain,
    },
    {
      id: REHAB_DIRECTIVE_TYPE.SPINAL_CANAL,
      value: REHAB_DIRECTIVE_TYPE.SPINAL_CANAL,
      label: translations.patientForms.medicalForm.sectionFive.spinalCanal,
    },
    {
      id: REHAB_DIRECTIVE_TYPE.AMPUTATION,
      value: REHAB_DIRECTIVE_TYPE.AMPUTATION,
      label: translations.patientForms.medicalForm.sectionFive.amputation,
    },
    {
      id: REHAB_DIRECTIVE_TYPE.RESPIRATORY_DISEASE,
      value: REHAB_DIRECTIVE_TYPE.RESPIRATORY_DISEASE,
      label:
        translations.patientForms.medicalForm.sectionFive.respiratoryDisease,
    },
    {
      id: REHAB_DIRECTIVE_TYPE.NEUROLOGICAL_DISEASE,
      value: REHAB_DIRECTIVE_TYPE.NEUROLOGICAL_DISEASE,
      label:
        translations.patientForms.medicalForm.sectionFive.neurologicalDiseases,
    },
    {
      id: REHAB_DIRECTIVE_TYPE.ONCOLOGICAL_DISEASE,
      value: REHAB_DIRECTIVE_TYPE.ONCOLOGICAL_DISEASE,
      label:
        translations.patientForms.medicalForm.sectionFive.oncologicalDisease,
    },
    {
      id: REHAB_DIRECTIVE_TYPE.TRANSPLANTS,
      value: REHAB_DIRECTIVE_TYPE.TRANSPLANTS,
      label: translations.patientForms.medicalForm.sectionFive.transplants,
    },
    {
      id: REHAB_DIRECTIVE_TYPE.GERIATRIC_REHAB,
      value: REHAB_DIRECTIVE_TYPE.GERIATRIC_REHAB,
      label: translations.patientForms.medicalForm.sectionFive.geriatricRehab,
    },
  ];

  return rehabTypeDirectiveOptions;
}

export function useSpecialRehabRequirementsItems() {
  const translations = useTranslations();

  const specialRehabRequirementsItems = [
    {
      elementName: "special_requirements_dialysis",
      label: translations.patientForms.medicalForm.sectionSix.dialysis,
      children: (
        <TextAreaWithLabel
          elementName="special_requirements_dialysis_description"
          flatModel
          label={
            translations.patientForms.medicalForm.sectionFive
              .otherTextfieldLabel
          }
          marginOverride={margin(0)}
        />
      ),
    },
    {
      elementName: "special_requirements_chemotherapy",
      label: translations.patientForms.medicalForm.sectionSix.chemotherapy,
    },
    {
      elementName: "special_requirements_immunosuppressants",
      label:
        translations.patientForms.medicalForm.sectionSix.immunosuppressants,
    },
    {
      elementName: "special_requirements_catheter",
      label: translations.patientForms.medicalForm.sectionSix.catheter,
    },
    {
      elementName: "special_requirements_peg",
      label: translations.patientForms.medicalForm.sectionSix.peg,
    },
    {
      elementName: "special_requirements_isolation",
      label: translations.patientForms.medicalForm.sectionSix.isolation,
    },
    {
      elementName: "special_requirements_ventilation",
      label: translations.patientForms.medicalForm.sectionSix.ventilation,
    },
    {
      elementName: "special_requirements_tracheostoma",
      label: translations.patientForms.medicalForm.sectionSix.tracheostoma,
    },
    {
      elementName: "special_requirements_weaning",
      label: translations.patientForms.medicalForm.sectionSix.weaning,
    },
    {
      elementName: "special_requirements_prosthesis",
      label: translations.patientForms.medicalForm.sectionSix.prosthesis,
    },
    {
      elementName: "special_requirements_overlength_bed",
      label: translations.patientForms.medicalForm.sectionSix.overlengthBed,
    },
    {
      elementName: "special_requirements_obesity",
      label: translations.patientForms.medicalForm.sectionSix.obesity,
    },
    {
      elementName: "special_requirements_impairment",
      label: translations.patientForms.medicalForm.sectionSix.impairment,
    },
    {
      elementName: "special_requirements_weight_bed",
      label: translations.patientForms.medicalForm.sectionSix.weightBed,
      children: (
        <TextInputWithLabel
          elementName="special_requirements_weight_bed_description"
          flatModel
          endAdornment={translations.abbreviations.kilogram.abbreviation}
          marginOverride={margin(0, 0, 1, 4.5)}
        />
      ),
    },
    {
      elementName: "special_requirements_relatives",
      label: translations.patientForms.medicalForm.sectionSix.relatives,
      children: (
        <TextAreaWithLabel
          elementName="special_requirements_relatives_description"
          flatModel
          label={
            translations.patientForms.medicalForm.sectionFive
              .otherTextfieldLabel
          }
          marginOverride={margin(0)}
        />
      ),
    },
    {
      elementName: "special_requirements_decubitus",
      label: translations.patientForms.medicalForm.sectionSix.decubitus,
      children: (
        <TextAreaWithLabel
          elementName="special_requirements_decubitus_description"
          flatModel
          label={
            translations.patientForms.medicalForm.sectionFive
              .otherTextfieldLabel
          }
          marginOverride={margin(0)}
        />
      ),
    },
    {
      elementName: "special_requirements_other",
      label: translations.patientForms.medicalForm.sectionSix.other,
      children: (
        <TextAreaWithLabel
          elementName="special_requirements_other_description"
          flatModel
          label={
            translations.patientForms.medicalForm.sectionFive
              .otherTextfieldLabel
          }
          marginOverride={margin(0)}
        />
      ),
    },
  ];

  return specialRehabRequirementsItems;
}

export function useTransportOptions() {
  const translations = useTranslations();

  const taxiOptions: RadioOptionV2[] = [
    {
      id: TRANSPORTATION_TAXI.WHEELCHAIR,
      value: TRANSPORTATION_TAXI.WHEELCHAIR,
      label: translations.patientForms.medicalForm.sectionSix.wheelChair,
    },
    {
      id: TRANSPORTATION_TAXI.STRETCHER,
      value: TRANSPORTATION_TAXI.STRETCHER,
      label: translations.patientForms.medicalForm.sectionSix.stretcher,
    },
    {
      id: TRANSPORTATION_TAXI.LYING_DOWN,
      value: TRANSPORTATION_TAXI.LYING_DOWN,
      label: translations.patientForms.medicalForm.sectionSix.lyingDown,
    },
  ];

  const transportOptions: RadioOptionV2[] = [
    {
      id: TRANSPORTATION_TYPE.PUBLIC,
      value: TRANSPORTATION_TYPE.PUBLIC,
      label:
        translations.patientForms.medicalForm.sectionSix.optionPublicTransport,
    },
    {
      id: TRANSPORTATION_TYPE.CAR,
      value: TRANSPORTATION_TYPE.CAR,
      label: translations.patientForms.medicalForm.sectionSix.car,
    },
    {
      id: TRANSPORTATION_TYPE.TAXI,
      value: TRANSPORTATION_TYPE.TAXI,
      label: translations.patientForms.medicalForm.sectionSix.taxi,
      subForm: (
        <RadioGroupV2
          elementName="transport_type_taxi_specification"
          flatModel
          label={
            translations.patientForms.medicalForm.sectionSix.taxiOptionLabel
          }
          options={taxiOptions}
          formControlLabelSx={{
            paddingBottom: sizing(0.5),
            marginLeft: sizing(4),
          }}
          formLabelSx={visuallyHidden as SxProps}
          radioSx={RADIO_WHITE_BACKGROUND}
        />
      ),
    },
    {
      id: TRANSPORTATION_TYPE.AMBULANCE,
      value: TRANSPORTATION_TYPE.AMBULANCE,
      label: translations.patientForms.medicalForm.sectionSix.ambulance,
      subForm: (
        <TextAreaWithLabel
          elementName="transport_type_ambulance_description"
          flatModel
          label={
            translations.patientForms.medicalForm.sectionSix
              .ambulanceDescriptionLabel
          }
          marginOverride={margin(0)}
        />
      ),
    },
  ];

  return transportOptions;
}
