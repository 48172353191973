import { EARLY_REHAB_CRITERIA, REHAB_FORM_NAMES } from "core/consts";
import { getErrorMessage } from "core/model/utils/errors";
import {
  assertUnreachable,
  formatDate,
} from "dsl/ecosystems/PatientForms/utils";
import { PdfFillerProps } from "../configs";
import { handlePdfCheckboxes } from "../utils";
import { GKVMedicalFormData } from "./modelDefinition";

export const GKVmedicalFormMapping = ({
  form,
  formData,
  locale,
}: PdfFillerProps<typeof REHAB_FORM_NAMES.GKV_MEDICAL>) => {
  Object.entries(formData).forEach(([key, value]) => {
    try {
      const formFieldName = key as keyof GKVMedicalFormData;
      switch (formFieldName) {
        // DATE FIELDS
        case "admission_date":
        case "discharge_date":
        case "patient_birthdate":
        case "operation_date":
        case "rehab_admission_outside_two_weeks_start_date":
        case "rehab_admission_within_two_weeks_start_date": {
          if (!value) break;

          const date = formatDate(value as number, locale);
          form.getTextField(formFieldName).setText(date);

          break;
        }
        // TEXT FIELDS
        case "complication_cause_cardiovascular_description":
        case "complication_cause_maindiagnosis_description":
        case "complication_cause_other_description":
        case "operation_description":
        case "rehab_focus_other_description":
        case "special_requirements_decubitus_description":
        case "special_requirements_dialysis_description":
        case "special_requirements_other_description":
        case "special_requirements_relatives_description":
        case "special_requirements_weight_bed_description":
        case "doctor_in_charge_in_hospital":
        case "doctor_in_charge_in_hospital_phone":
        case "current_therapy":
        case "icd_code_1_code":
        case "icd_code_1_diagnosis":
        case "icd_code_1_reason":
        case "icd_code_2_code":
        case "icd_code_2_diagnosis":
        case "patient_first_name":
        case "patient_last_name":
        case "recommended_reha_type_ambulant_mobil":
        case "rehab_admission_outside_two_weeks_explanation":
        case "icd_code_2_reason":
        case "icd_code_3_code":
        case "icd_code_3_diagnosis":
        case "icd_code_3_reason":
        case "icd_code_4_code":
        case "operations":
        case "icd_code_4_diagnosis":
        case "icd_code_4_reason":
        case "recommended_reha_type_stationary":
        case "icd_code_5_code":
        case "infections":
        case "rehabilitation_goals":
        case "insurance_number":
        case "icd_code_5_diagnosis":
        case "reha_capacity_other":
        case "reha_capacity_partial":
        case "icd_code_5_reason":
        case "transport_type_ambulance_description": {
          if (value != null && typeof value !== "string") {
            console.warn("incorrect value type for text field", {
              formFieldName,
              value,
            });
            break;
          }

          form.getTextField(formFieldName).setText(value || "");
          break;
        }
        // NUMBER FIELD
        case "singer_assessment_score": {
          if (value != null && typeof value !== "number") {
            console.warn(
              "incorrect value type for singer_assessment_score field",
              {
                formFieldName,
                value,
              },
            );
            break;
          }

          form.getTextField(formFieldName).setText(value?.toString() || "");
          break;
        }

        // CHECKBOX FIELDS
        case "special_requirements_catheter":
        case "special_requirements_tracheostoma":
        case "special_requirements_chemotherapy":
        case "special_requirements_decubitus":
        case "special_requirements_dialysis":
        case "special_requirements_immunosuppressants":
        case "special_requirements_impairment":
        case "special_requirements_isolation":
        case "special_requirements_obesity":
        case "special_requirements_other":
        case "special_requirements_overlength_bed":
        case "special_requirements_ventilation":
        case "special_requirements_peg":
        case "special_requirements_prosthesis":
        case "special_requirements_weaning":
        case "special_requirements_weight_bed":
        case "special_requirements_relatives":
        case "complication_cause_cardiovascular":
        case "complication_cause_maindiagnosis":
        case "complication_cause_other": {
          if (value != null && typeof value !== "boolean") {
            console.warn("incorrect value type for checkbox field", {
              formFieldName,
              value,
            });
            break;
          }

          const checkbox = form.getCheckBox(formFieldName);
          if (value) {
            checkbox.check();
          } else {
            checkbox.uncheck();
          }
          break;
        }
        // RADIO FIELDS
        case "rehab_focus":
        case "transport_type":
        case "transport_type_taxi_specification":
        case "recommended_reha_type":
        case "rehab_admission_type":
        case "special_requirements_required":
        case "rehab_directive_type":
        case "complication_in_treatment_process":
        case "rehab_directive_state":
        case "interim_prosthesis_fitted":
        case "limited_capacity":
        case "early_rehabilitation_measures":
        case "wound_closed":
        case "gender":
        case "reha_capacity":
        case "require_accompanying_person":
        case "infections_state":
        case "performed_treatment": {
          const radio = form.getRadioGroup(formFieldName);
          if (value == null) {
            radio.clear();
            return;
          }
          if (typeof value !== "number") {
            console.warn("incorrect value type for radio field", {
              formFieldName,
              value,
            });
            break;
          }

          radio.select(value.toString());
          break;
        }
        // CHECKBOX GROUP FIELDS
        case "reha_capacity_early_rehab_criteria": {
          handlePdfCheckboxes({
            form,
            possibleValues: EARLY_REHAB_CRITERIA,
            formFieldName,
            value,
          });
          break;
        }
        case "singer_assessment_form": {
          if (!value) break;
          Object.entries(value).forEach(([singerKey, singerValue]) => {
            try {
              if (singerKey === "__typename") return;

              const radio = form.getRadioGroup(singerKey);

              if (singerValue == null) {
                radio.clear();
                return;
              }

              if (typeof singerValue !== "number") {
                console.warn("incorrect value type for radio field", {
                  formFieldName,
                  value,
                });
                return;
              }

              radio.select(singerValue.toString());
              form
                .getTextField(`${singerKey}_score`)
                .setText(singerValue.toString());
            } catch (err) {
              console.error(
                `error setting singer field ${singerKey} in PDF: ${getErrorMessage(
                  err,
                )}`,
              );
            }
          });

          break;
        }
        // IGNORE DOES NOT EXIST IN FORM
        case "singer_assessment_date": {
          break;
        }
        default: {
          assertUnreachable(formFieldName);
        }
      }
    } catch (err) {
      console.error(
        `error setting field ${key} in PDF: ${getErrorMessage(err)}`,
      );
    }
  });
};
